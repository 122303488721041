/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react'
import axios from 'axios'

import { graphql, useStaticQuery, Link } from 'gatsby'
import RenderForm from 'gatsby-plugin-cloudofertas'

/* COMPONENTS */
import Pagina from '../components/Pagina'
import Loading from '../components/Loader/loader'
// import Whatsapp from '../assets/icons/wpp.svg'

/* STYLES */
import './trabalhe-conosco.css'

export default function TrabalheConosco () {
  const data = useStaticQuery(
    graphql`
    query {
      pagina:contentfulPagina(path: { eq: "/trabalhe-conosco/" }) {
        path
        metaTitle
        metaDescription
        tags
      }
      cloudOfertasForm(name: {eq: "TRABALHE_CONOSCO"}) {
        id
        formTypeField {
          name
          type
          required
          style
          length
          label
          extension
          formLov {
            formLovData {
              id
              value
              createdDate
            }
          }
        }
      }
    }
  `
  )

  const urlApi = `${process.env.GATSBY_CLOUDOFERTAS_API_URL}/sites/${process.env.GATSBY_CLOUDOFERTAS_SITE_ID}/form-data/${data.cloudOfertasForm.id}/`
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [disableBtn, setDisableBtn] = useState(false)

  // const onSubmit = async (data) => {
  //   setDisableBtn(true)
  //   setLoading(true)
  //   try {
  //     const config = {
  //       headers: {
  //         accessToken: process.env.GATSBY_CLOUDOFERTAS_SITE_KEY
  //       }
  //     }

  //     const result = await axios.post(urlApi, data, config)
  //     setLoading(false)
  //     setSubmitSuccess(true)

  //     console.log('result:', result)
  //   } catch (err) {
  //     console.log('ERRO: ', err)
  //   }
  // }

  return (
    <Fragment>
      {/* <button className='d-flex flex-row-reverse botao-whats-fixo'>
        <div className='d-flex'>
  <img src={Whatsapp} alt='Whatsapp' className='botao-fixo-zap' />
  <p className='mt-3'>Solicite agora!</p>
  </div>
</button> */}
      <Loading loader={loading}/>
      <Pagina pagina={data.pagina} />
      <section id="work-with-us" className="theme--quaternary pb-4">
        <div id="img-top" className="w-100"></div>
        <div className="container">
          <div className="btnCurriculo col-12 p-0">
            <hgroup className="mt-3 mt-sm-4">
              <h1 className="theme-secundary">TRABALHE CONOSCO</h1>
              <h2 className="m-0">Quer fazer parte da nossa equipe?</h2>
            </hgroup>
            <p>Envie o seu currículo e assim que houver uma vaga correspondente ao seu perfil, entraremos em contato.</p>
            {/* <Link to="https://drogaleste.solides.jobs/" target="_blank" rel="noreferrer" className="btn btn-block theme--secundary btn-outline-#1A3797 theme-border-2 text-white my-3">Clique aqui para enviar o seu currículo</Link> */}
          </div>
          { data.cloudOfertasForm.formTypeField && submitSuccess === false &&
            <RenderForm
              disabledButton={disableBtn}
              formData={data.cloudOfertasForm}
              // onSubmit={onSubmit}
              setSuccessSubmit={setSubmitSuccess}
              placeholder={true}
              rowStyle='row'
              inputStyle='custom-form d-flex flex-column mt-4'
              inputSearchStyle='custom-input-search mt-4'
              btnContainerStyle='custom-form col-12'
              btnStyle='btn btn-block theme--secundary btn-outline-#1A3797 theme-border-2 text-white my-3'
              btnName='ENVIAR'
            />
          }
          {submitSuccess &&
            <div className='row'>
              <div className='col'>
                <div id="alertSucesso" className="alert alert-success" role="alert">
                  Obrigado por entrar em contato com a gente. Responderemos em até 48h.
                </div>
              </div>
            </div>
          }
        </div>
      </section>
    </Fragment>
  )
}
